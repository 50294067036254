import React from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import classes from "./DemoAccountLoginCredentials.module.css";
import { toast } from "react-toastify";

const DemoAccountLoginCredentials = ({ hr = false }) => {
  return (
    <div className={classes?.mainContainer}>
      {hr && <hr />}
      <h5>Demo Account Login Credentials</h5>
      <table>
        <thead>
          <tr>
            <th>Account Type</th>
            <th>Email</th>
            <th>Password</th>
          </tr>
        </thead>
        <tbody>
          {accountCredentials.map((account, index) => (
            <tr key={index}>
              <td>{account.accountType}</td>
              <td title="Click to Copy" className={classes?.cp}>
                <CopyToClipboard
                  text={account.email}
                  onCopy={() => toast.success("Email Copied")}
                >
                  <span className={classes?.copyText}>{account.email}</span>
                </CopyToClipboard>
              </td>
              <td className={classes?.cp} title="Click to Copy">
                <CopyToClipboard
                  text={account.password}
                  onCopy={() => toast.success("Password Copied")}
                >
                  <span className={classes?.copyText}>{account.password}</span>
                </CopyToClipboard>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DemoAccountLoginCredentials;

const accountCredentials = [
  {
    accountType: "Admin",
    email: "abfcodes@gmail.com",
    password: "12345678",
  },
];
