import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Route, BrowserRouter as Router, Link } from "react-router-dom";
import CurrentTip from "../Screens/CurrentTip";
import ManageTrip from "../Screens/ManageTrip";
import Feedback from "../Screens/Feedback";
import HelpSupport from "../Screens/HelpSupport";
import Notification from "../Screens/Notification";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";

import {
  logoutIcon,
  refreshIcon,
  logo,
  chapterIcon,
  faqIcon,
  users,
  pages,
  compliant,
  insurance,
  chat,
  giftVoucher,
  goal,
  aboutUsImg,
  headerLink
} from "../constant/imagePath";
import { useHistory, useLocation } from "react-router-dom";
import CategoryScreen from "../Screens/CategoriesScreen";
import ChaptersScreen from "../Screens/ChaptersScreen";
import VideosScreen from "../Screens/VideosScreen";
import { signOutRequest } from "../store/Actions/authAction";
import FaqScreen from "../Screens/FaqScreen";
import UsersScreen from "../Screens/UsersScreen";
import PagesScreen from "../Screens/Pages";
import PageDetails from "../Screens/PageDetail";
import Seo from "../Screens/Seo";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import ContentEditor from "../Screens/ContentEditor";
import TermAndCondition from "../Screens/TermAndCondition";
import ResourcesScreen from "../Screens/ResourcesScreen";
import ForumScreen from "../Screens/ForumScreen";
import OurMotoSection from "../Screens/OurMotoSection";
import WebDashboardScreen from "../Screens/WebDashboardScreen";
import Vouchers from "../Screens/Vouchers";
import AboutUs from "../Screens/AboutUs";
import Testimonials from "../Screens/Testimonials";
import Social from "../Screens/Social";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNavbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const interval = useRef();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("/");
  const [lastRefresh, setLastRefresh] = useState(0);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const pathName = location?.pathname;
    setSelected(pathName);
    setLastRefresh(0);
  }, [location]);

  useEffect(() => {
    interval.current = setInterval(() => {
      setLastRefresh((pre) => pre + 1);
    }, 60000);
    return () => clearInterval(interval.current);
  }, []);

  // useEffect(() => {
  //   history.listen((location, action) => {
  //     console.log(location, "history");

  //     setSelected(location?.pathname);
  //   });
  // }, [history]);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {/* {open && <img src={logo} alt="" className="img-fluid" />} */}
          {open && <h3>{"Demo"}</h3>}
          <IconButton onClick={handleDrawerToggle} className="navHeaderIconBtn">
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          style={{
            marginTop: "25px",
          }}
        >
          <Link to="/" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Sections"}
              style={
                selected == "/"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={chapterIcon} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Sections"}
                onClick={() => {
                  console.log("Sections");
                }}
              />
            </ListItem>
          </Link>

          <Link to="/faq" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"FAQ"}
              style={
                selected == "/faq"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={faqIcon} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"FAQ"}
                onClick={() => {
                  console.log("FAQ");
                }}
              />
            </ListItem>
          </Link>

          <Link to="/forum" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Forum"}
              style={
                selected == "/forum"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={chat} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Forum"}
                onClick={() => {
                  console.log("Forum");
                }}
              />
            </ListItem>
          </Link>

          {/* users */}
          <Link to="/users" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Users"}
              style={
                selected == "/users"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={users} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Users"}
                onClick={() => {
                  console.log("Users");
                }}
              />
            </ListItem>
          </Link>

          {/* pages */}
          <Link to="/pages" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Pages"}
              style={
                selected == "/pages"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={pages} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Pages"}
                onClick={() => {
                  console.log("Pages");
                }}
              />
            </ListItem>
          </Link>

          {/* privacy-policy */}
          <Link to="/privacy-policy" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Privacy Policy"}
              style={
                selected == "/privacy-policy"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={compliant} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Privacy Policy"}
                onClick={() => {
                  console.log("Privacy Policy");
                }}
              />
            </ListItem>
          </Link>

          {/* TermAndCondition */}
          <Link to="/TermAndCondition" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Privacy Policy"}
              style={
                selected == "/TermAndCondition"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={insurance} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Terms & Conditions"}
                onClick={() => {
                  console.log("Terms & Conditions");
                }}
              />
            </ListItem>
          </Link>
          <Link to="/WebDashboard" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Web Dashboard"}
              style={
                selected == "/WebDashboard"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={insurance} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Web Dashboard"}
                onClick={() => {
                  console.log("Web Dashboard");
                }}
              />
            </ListItem>
          </Link>

          {/* our-moto */}
          <Link to="/our-moto" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Privacy Policy"}
              style={
                selected == "/our-moto"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={goal} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Our Moto Section"}
                onClick={() => {
                  console.log("Our Moto Section");
                }}
              />
            </ListItem>
          </Link>
          {/* voucher */}
          <Link to="/voucher" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Voucher"}
              style={
                selected == "/voucher"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={giftVoucher} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Voucher"}
                onClick={() => {
                  console.log("Voucher");
                }}
              />
            </ListItem>
          </Link>
          {/* Social */}
          <Link to="/header-link" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Header Link"}
              style={
                selected == "/header-link"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={headerLink} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Header Link"}
                onClick={() => {
                  console.log("Header");
                }}
              />
            </ListItem>
          </Link>
          {/* Testimonials */}
          <Link to="/Testimonials" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"Testimonials"}
              style={
                selected == "/Testimonials"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={aboutUsImg} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Testimonials"}
                onClick={() => {
                  console.log("About Us");
                }}
              />
            </ListItem>
          </Link>
          <Link to="/about-us" className="mouse-cursor sideBarNavLink">
            <ListItem
              button
              key={"about-us"}
              style={
                selected == "/about-us"
                  ? { borderLeft: "5px solid #6236CC", paddingLeft: "11px" }
                  : {}
              }
            >
              <ListItemIcon>
                <img src={aboutUsImg} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"About Us"}
                onClick={() => {
                  console.log("About Us");
                }}
              />
            </ListItem>
          </Link>

          <div
            className="sideBarNavLink mouse-cursor"
            onClick={(e) => {
              dispatch(signOutRequest());
            }}
          >
            <ListItem button key={"logout"}>
              <ListItemIcon>
                <img src={logoutIcon} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText
                primary={"Log out"}
                onClick={() => {
                  console.log("logout");
                }}
              />
            </ListItem>
          </div>
          <div
            className="sideBarNavLink mouse-cursor"
            style={{
              marginTop: "75px",
            }}
          >
            {open && (
              <p
                style={{
                  // fontFamily: "Cabin",
                  // fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "19px",
                  letterSpacing: "0.03em",
                  color: "#22003B",
                  opacity: 0.75,
                  padding: "0px 21px",
                }}
              >
                Updated {lastRefresh} mins ago
              </p>
            )}
            <ListItem
              button
              key={"Refresh Page"}
              onClick={() => {
                window.location.reload();
                console.log("Refresh Page");
              }}
            >
              <ListItemIcon>
                <img src={refreshIcon} alt="" className="img-fluid" />
              </ListItemIcon>
              <ListItemText primary={"Refresh Page"} />
            </ListItem>
          </div>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, backgroundColor: "#faf9ff", height: "100vh" }}
        // sx={{ flexGrow: 1, p: 3, backgroundColor: "#faf9ff", height: "100vh", }}
      >
        {/* <Header /> */}
        <Route path="/" exact component={CategoryScreen} />
        <Route path="/chapters" exact component={ChaptersScreen} />
        <Route path="/videos" exact component={VideosScreen} />
        <Route path="/faq" exact component={FaqScreen} />
        <Route path="/users" exact component={UsersScreen} />
        <Route path="/pages" exact component={PagesScreen} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/ContentEditor" exact component={ContentEditor} />
        <Route path="/TermAndCondition" exact component={TermAndCondition} />
        <Route path="/detail" exact component={PageDetails} />
        <Route path="/seo" exact component={Seo} />
        <Route path="/feedback" exact component={Feedback} />
        <Route path="/helpsupport" exact component={HelpSupport} />
        <Route path="/resources" exact component={ResourcesScreen} />
        <Route path="/forum" exact component={ForumScreen} />
        <Route path="/our-moto" exact component={OurMotoSection} />
        <Route path="/voucher" exact component={Vouchers} />
        <Route path="/header-link" exact component={Social} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/WebDashboard" exact component={WebDashboardScreen} />
        <Route path="/Testimonials" exact component={Testimonials} />

        {/* <Footer /> */}
      </Box>
    </Box>
  );
};

export default SideNavbar;
