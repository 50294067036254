import React, { useState, useEffect } from "react";
import { underProducation } from "../constant/imagePath";

const Notification = (props) => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="row">
        <div className="col-md-12">
          <img src={underProducation} alt="" className="img-fluid" />
          <p>Notification Page is Under Development</p>
        </div>
      </div>
    </section>
  );
};

export default Notification;
